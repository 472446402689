<template>
  <c-box>
    <c-drawer
      :isOpen="isOpen"
      placement="right"
      :on-close="
        () => {
          $emit('close');
        }
      "
    >
      <c-drawer-overlay />
      <c-drawer-content>
        <c-drawer-body
          display="flex"
          overflowY="scroll"
          flexDirection="column"
          pb="8"
        >
          <div class="details">
            <img
              :src="channel.logoUrl"
              alt=""
              v-if="channel.logoUrl"
              width="50px"
            />
            <span class="first_letter" v-else>{{
              getFirstLetter(user.name)
            }}</span>

            <h3>{{ user.name }}</h3>
            <p>{{ channel.description }}</p>
            <div>
              <c-button
                :isLoading="btnLoading"
                size="sm"
                variant-color="vc-orange"
                @click="onJoinChannelChange"
              >
                {{ isMember ? 'Leave Channel' : 'Join Channel' }}
              </c-button>
            </div>
          </div>
          <div class="info">
            <div class="msgTitle">
              <span>Participants ({{ members.length }})</span>
            </div>

            <div class="participants">
              <div style="text-align: center" v-if="loading">
                <c-spinner ml="2" color="orange" thickness="2px" />
              </div>
              <div v-else>
                <br />
                <div
                  v-for="participant in members"
                  :key="participant.id"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <UserItem :user="participant" @close="$emit('close')" />

                  <c-badge
                    mx="2"
                    variant-color="blue"
                    v-if="creatorId == participant.userId"
                    >Admin</c-badge
                  >
                </div>
              </div>
            </div>
            <br /><br />
            <div style="text-align: center" v-if="creatorId == userId">
              <c-button size="sm" class="mr-5" @click="showMembersModal = true">
                Add Participants
              </c-button>
              <c-button
                :isLoading="deleteBtnLoading"
                size="sm"
                variant-color="red"
                @click="deleteAChannel"
              >
                Delete Channel
              </c-button>
            </div>
          </div>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>

    <c-modal
      :is-open="showMembersModal"
      :on-close="closeMembersModal"
      is-centered
      size="lg"
      style="border-radius: 10px"
    >
      <c-modal-content ref="content">
        <c-modal-header>Add Members</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <c-box mt="5">
            <UsersLists
              type="newUsers"
              :show-checkbox="true"
              heightId="fixedHeight"
              :searchable="true"
              @usersSelected="addedParticipants"
            />
          </c-box>
        </c-modal-body>
        <c-modal-footer
          d="flex"
          alignItems="center"
          justify-content="space-between"
        >
          <c-button @click="showMembersModal = false">Cancel</c-button>
          <c-button
            variant-color="vc-orange"
            mr="3"
            @click="addMultipleMembers"
          >
            Add Participants
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>

<script>
import store from '@/store';
import UserItem from './UserItem.vue';
import UsersLists from './UsersLists.vue';
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import {
  getChannelDetails,
  leaveAChannel,
  deleteAChatChannel,
  addMultipleParticipants,
} from '@/services/messages.js';

export default {
  name: 'ChannelDetailsDrawer',

  components: {
    UserItem,
    UsersLists,
  },
  props: {
    user: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMembersModal: false,
      deleteBtnLoading: false,
      placeHolderImage,
      channel: {},
      members: [],
      addedMembers: [],
      loading: true,
      btnLoading: false,
      isMember: false,
      creatorId: null,
      userId: store.state.auth.user.id,
    };
  },
  created() {
    this.getChannel();
  },
  methods: {
    closeMembersModal() {
      this.showMembersModal = false;
    },
    addedParticipants(usersArray) {
      if (usersArray.length === 0) {
        this.addedMembers = [];
        return;
      }

      usersArray = usersArray.map((user) => {
        return {
          ...user,
          userId: user.id,
          name: user.firstname
            ? user.firstname + ' ' + user.lastname
            : 'No name',
          firstname: user.firstname,
          lastname: user.lastname,
          OnlineStatus: user.OnlineStatus,
          profilePhoto: user.profilePhoto,
          company: user.companyName,
        };
      });

      this.addedMembers = usersArray;
    },
    async addMultipleMembers() {
      const participants = this.addedMembers.map((member) => {
        return {
          channel_id: this.channel.id,
          participant_id: member.id,
        };
      });

      try {
        await addMultipleParticipants(participants);
        this.members = [...this.members, ...this.addedMembers];
        this.showMembersModal = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getChannel() {
      const channelId = this.user.id;
      try {
        const response = await getChannelDetails(channelId);
        const channel = response.data.chat_channel[0];

        const userId = store.state.auth.user.id;
        this.creatorId = channel.created_by;

        for (let i = 0; i < channel.participants.length; i++) {
          if (channel.participants[i].participant.id === userId) {
            this.isMember = true;
            break;
          } else {
            this.isMember = false;
          }
        }

        this.channel = channel;
        const formatParticipants = channel.participants.map((part) => {
          return {
            ...part,
            userId: part.participant.id,
            name: part.participant.firstname
              ? part.participant.firstname + ' ' + part.participant.lastname
              : 'No name',
            firstname: part.participant.firstname,
            lastname: part.participant.lastname,
            OnlineStatus: part.participant.OnlineStatus,
            profilePhoto: part.participant.profilePhoto,
            companies: part.participant.companies,
            company:
              part.participant.companies?.length > 0
                ? part.participant.companies
                    .map((company) => company.name)
                    .join(', ')
                : 'No company',
          };
        });

        this.members = formatParticipants;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async onJoinChannelChange() {
      this.btnLoading = true;

      const loggedInUserId = store.state.auth.user.id;
      const channelId = this.channel.id;

      console.log(this.channel);
      try {
        await leaveAChannel(loggedInUserId, channelId);
        this.isMember = !this.isMember;
        this.btnLoading = false;
        this.$emit('close');
        this.$router.push('/app/messaging');
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    getFirstLetter(name) {
      if (name) {
        return name.charAt(0).toUpperCase();
      }
    },
    deleteAChannel() {
      this.deleteBtnLoading = true;

      console.log(this.channel.id);

      deleteAChatChannel(this.channel.id)
        .then(() => {
          this.deleteBtnLoading = false;
          this.$emit('close', 'deleted');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: rgba(235, 180, 163, 0.13);

  .first_letter {
    font-size: 20px;
    font-weight: 600;
    background-color: #e7592e;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.8rem;
  }
  small {
    font-size: 0.8rem;
    color: #666;
  }
  button {
    margin-top: 20px;
  }
}

.info {
  .msgTitle {
    display: flex;
    align-items: center;
    background: rgba(66, 103, 178, 0.14);
    border-radius: 3px;
    margin-top: 20px;

    span {
      padding: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: var(--color-grey);
    }
  }

  .participants {
    height: auto;
    max-height: 500px;
    overflow-y: scroll;
  }
}
</style>
