<template>
  <div class="messaging" :id="height">
    <c-box d="flex" justify-content="space-between" class="messaging__head">
      <div class="messaging__head__info">
        <span
          class="first_letter"
          v-if="selectedChat.isChannel && !selectedChat.img"
          >{{ getFirstLetter(selectedChat.name) }}</span
        >
        <img
          :src="selectedChat.img ? selectedChat.img : placeHolderImage"
          alt="profile"
          width="40px"
          v-else
        />
        <span
          class="online_dot"
          v-if="selectedChat.OnlineStatus == 'true'"
        ></span>
        <div>
          <h2 @click="goToProfile(selectedChat)">
            {{ selectedChat.name }}
          </h2>
          <small v-if="!selectedChat.isChannel">{{
            selectedChat.OnlineStatus == 'true' ? 'Online' : 'Offline'
          }}</small>

          <small v-else>Channel</small>
        </div>
      </div>
      <div class="messaging__head__icons">
        <!-- <i class="fas fa-search"></i> -->
        <!-- <i class="fas fa-ellipsis-v"></i> -->
        <span @click="isUserDrawerOpen = true" v-if="selectedChat.isChannel">
          <i class="fas fa-ellipsis-h"></i>
        </span>
        <span @click="closeMessage()">
          <i class="fas fa-times"></i>
        </span>
      </div>
    </c-box>

    <div class="messaging__chats" ref="chatContainer">
      <div class="messaging__chats__noMessage" v-if="messageLoading">
        <c-spinner ml="2" color="orange" thickness="2px" />
      </div>

      <div
        class="messaging__chats__noMessage"
        v-if="noMessages && !messageLoading"
      >
        <div class="center" textAlign="center">
          <img
            src="@/assets/icons/sdg/nomessage.svg"
            alt="no_message"
            width="250px"
          />

          <h2>No Conversations Yet</h2>
        </div>
      </div>

      <div v-else class="messaging__chats__messages">
        <infinite-loading
          @infinite="getMoreMessages"
          direction="top"
          v-if="infiniteLoad"
        >
          <span slot="no-more">
            <b></b>
          </span>
          <span slot="no-results">
            <b></b>
          </span>
        </infinite-loading>
        <div
          v-for="(msg, index) in userMessages"
          :key="index"
          class="user_message"
        >
          <img
            :src="
              msg.sender.profilePhoto
                ? msg.sender.profilePhoto
                : placeHolderImage
            "
          />

          <div class="details">
            <div>
              <h3 @click="goToProfile(msg.sender)">
                {{ msg.sender.firstname }} {{ msg.sender.lastname }}
              </h3>
              <small>{{ getMomentDates(msg.created_at) }}</small>
            </div>

            <p>{{ msg.content }}</p>
            <img v-if="msg.image" :src="msg.image" alt="img" />
          </div>
        </div>
      </div>
    </div>

    <c-box class="messaging__footer">
      <div class="fileList" v-if="uploadedFile.name">
        <div>
          <img :src="uploadedFile.src" alt="file" />
          <span
            >{{ uploadedFile.name }}
            <br />
            <small>Attached</small>
          </span>
        </div>
        <i class="fas fa-times" @click="removeFile"></i>
      </div>
      <textarea
        placeholder="Type a message"
        class="text_area"
        v-model="messagePayload.message"
        v-on:keyup.enter="sendMessage"
      ></textarea>

      <div>
        <div class="messaging__footer__options">
          <div>
            <!-- <input ref="fileUpload" type="file" @change="onChangeFile" />

            <span @click="selectFile()">
              <i class="fas fa-paperclip"></i>
            </span>

            <i class="fas fa-images"></i> -->
            <c-menu>
              <c-menu-button size="xs" variant="link" style="margin-top: -5px">
                <i class="fas fa-smile"></i>
              </c-menu-button>
              <c-menu-list>
                <VEmojiPicker @select="selectEmoji" />
              </c-menu-list>
            </c-menu>
          </div>
          <c-button size="sm" variant-color="vc-orange" @click="sendMessage">
            Send
          </c-button>
        </div>
      </div>
    </c-box>

    <ChannelDetailsDrawer
      :user="selectedChat"
      :isOpen="isUserDrawerOpen"
      @close="closeChannelDrawer"
      v-if="isUserDrawerOpen"
    />
  </div>
</template>

<script>
import store from '@/store';
import cloneDeep from 'lodash.clonedeep';
import { VEmojiPicker } from 'v-emoji-picker';
import ChannelDetailsDrawer from './ChannelDetailsDrawer.vue';
import { getMomentDates } from '@/helpers/utils.js';
import {
  sendChannelMessage,
  sendChat,
  subscribeToChannelMessages,
  subscribeToChatMessages,
  getOneOnOneMessages,
  getChannelMessages,
} from '@/services/messages.js';
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'MessagingArea',
  components: {
    VEmojiPicker,
    ChannelDetailsDrawer,
    InfiniteLoading,
  },
  props: {
    height: String,
    selectedChat: {
      type: Object || null,
    },
  },

  data() {
    return {
      messageLoading: true,
      infiniteLoad: false,
      messagesTotal: 0,
      messageOffset: 15,
      messagesLimit: 15,
      placeHolderImage,
      isUserDrawerOpen: false,
      userMessages: [],
      messagePayload: {
        message: '',
        image: '',
        emoji: '',
      },
      uploadedFile: {
        file: '',
        name: '',
        src: '',
      },
    };
  },
  created() {
    this.messagesTotal = this.selectedChat.messageTotal;
    this.scrollToBottom();
  },
  computed: {
    noMessages() {
      // when this.user has no messages or its not set in props
      return this.userMessages.length === 0;
    },
  },
  watch: {
    selectedChat(v) {
      this.messagesTotal = v.messageTotal;
      this.messagesLimit = 15;
      this.messageOffset = 0;
      this.userMessages = [];
      this.infiniteLoad = false;
      this.messageLoading = true;
      this.initializeSubscriptions();
      console.log('watch', v);
      // this.userMessages = v.messages.reverse();
    },
  },
  mounted() {
    this.initializeSubscriptions();

    this.scrollToBottom();
  },
  // updated() {
  //   this.scrollToBottom();
  // },
  methods: {
    getMomentDates,
    initializeSubscriptions() {
      if (this.selectedChat.isChannel) {
        this.subscribeToChannelMessage();
      }

      if (!this.selectedChat.isChannel) {
        this.subscribeToUserMessage();
      }
    },
    subscribeToChannelMessage() {
      subscribeToChannelMessages(this.selectedChat.id).subscribe({
        next: (res) => {
          console.log('sub channel');
          const messages = res.data.chat_channel_message;

          this.userMessages = messages.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );
          this.infiniteLoad = true;
          this.scrollToBottom();
          this.messageLoading = false;
        },
        error(error) {
          this.messageLoading = false;
          console.error(error);
        },
      });
    },
    subscribeToUserMessage() {
      const loggedInUser = store.state.auth.user.id;
      const profileUserId = this.selectedChat.id;

      const limit = 15;
      const offset = 0;

      subscribeToChatMessages({
        loggedInUser,
        profileUserId,
        limit,
        offset,
      }).subscribe({
        next: (res) => {
          console.log('one on one', res);
          const messages = res.data.personal_chat_message;
          // this.messagesTotal =
          //   newMessage.relation.messages_aggregate.aggregate.count;
          this.userMessages = messages.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );
          this.infiniteLoad = true;
          this.scrollToBottom();
          this.messageLoading = false;
        },
        error(error) {
          this.messageLoading = false;
          console.error(error);
        },
      });
    },
    selectEmoji(emoji) {
      this.messagePayload.message = this.messagePayload.message + emoji.data;
    },
    async sendMessage() {
      if (this.messagePayload.message) {
        this.userMessages.push({
          content: this.messagePayload.message,
          created_at: new Date().toLocaleTimeString(),
          image: this.messagePayload.image,
          sender: {
            firstname: store.state.auth.user.firstname,
            lastname: store.state.auth.user.lastname,
            profilePhoto: store.state.auth.user.profilePhoto,
          },
        });
        this.scrollToBottom();
        await this.checkingSendingType(this.messagePayload.message);
        this.messagePayload.message = '';
        this.removeFile();
      }

      // this.messagePayload.message = '';
    },
    checkingSendingType(content) {
      if (this.selectedChat.isChannel) this.sendToChannel(content);
      else this.sendToUser(content);
    },
    async sendToChannel(content) {
      const payload = {
        channel_id: this.selectedChat.id,
        content: content,
      };

      try {
        await sendChannelMessage(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async sendToUser(content) {
      const payload = {
        recipientId: this.selectedChat.id,
        content: content,
      };

      try {
        await sendChat(payload);
      } catch (error) {
        console.log(error);
      }
    },
    selectFile() {
      this.$refs.fileUpload.click();
    },
    onChangeFile(e) {
      const files = e.type === 'change' ? e.target.files : e.dataTransfer.files;
      if (!files.length) return;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.messagePayload.image = reader.result;
        this.uploadedFile.file = file;
        this.uploadedFile.name = file.name;
        this.uploadedFile.src = reader.result;
      };
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
    removeFile() {
      this.uploadedFile = {
        file: '',
        name: '',
        src: '',
      };
    },
    getFirstLetter(name) {
      if (name) {
        return name.charAt(0).toUpperCase();
      }
    },
    goToProfile(user) {
      if (!user.isChannel) {
        this.$router.push({
          name: 'Profile',
          params: {
            userId: user.id,
          },
        });
      } else {
        this.isUserDrawerOpen = true;
      }
    },
    async getMoreMessages($state) {
      const loggedInUser = store.state.auth.user.id;
      const profileUserId = this.selectedChat.id;
      let messageResponse = [];

      this.messageOffset = this.messageOffset + this.messagesLimit;

      try {
        if (this.selectedChat.isChannel === true) {
          const response = await getChannelMessages(
            profileUserId,
            this.messagesLimit,
            this.messageOffset
          );

          messageResponse = response.data.chat_channel_message;
        } else {
          const response = await getOneOnOneMessages({
            loggedInUser,
            profileUserId,
            limit: this.messagesLimit,
            offset: this.messageOffset,
          });

          messageResponse = response.data.personal_chat_message;
        }

        const messages = [...messageResponse, ...cloneDeep(this.userMessages)];

        this.userMessages = messages.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        $state.loaded();
        console.log(this.messagesTotal, this.userMessages.length);
        if (
          messageResponse.length === 0 ||
          this.messagesTotal === this.userMessages.length
        ) {
          console.log('no more');
          this.messageOffset = 15;
          this.messagesLimit = 15;
          this.infiniteLoad = false;
          $state.complete();
        }
        this.messageLoading = false;
      } catch (error) {
        this.messageLoading = false;
        this.infiniteLoad = false;
        console.log(error);
      }
    },
    closeMessage() {
      this.$emit('close');
    },
    closeChannelDrawer(type) {
      if (type === 'deleted') {
        this.isUserDrawerOpen = false;
        this.$router.push({ name: 'messaging' });
      } else {
        this.isUserDrawerOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#auto {
  height: 90vh;
}
#footerArea {
  height: 550px;
}
.messaging {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__head {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    // border-bottom: 1px solid var(--color-grey);
    margin-bottom: 10px;
    // background-color: #e1e7f7;

    border-bottom: 1px solid #e1e7f7;

    &__info {
      display: flex;
      align-items: center;

      .first_letter {
        font-size: 20px;
        font-weight: 600;
        background-color: #e7592e;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
      }

      img {
        width: 45px;
        height: 45px;
        border-radius: 10%;
        margin-right: 15px;
      }

      .online_dot {
        height: 15px;
        width: 15px;
        background-color: #088b15;
        border-radius: 50%;
        display: inline-block;
        margin: 40px 15px 0 -30px;
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
        color: var(--color-primary);
        cursor: pointer;
      }

      p {
        font-size: 12px;
        color: #002b49;
        font-weight: 500;
      }

      small {
        color: #002b49;
        font-weight: 500;
      }
      svg {
        background-color: rgb(250, 250, 250);
        padding: 5px;
        font-size: 20px;
        color: rgb(225, 231, 247);
        border-radius: 4px;
        margin-right: 15px;
        width: 35px;
        height: 35px;
      }
    }

    &__icons {
      svg {
        font-size: 18px;
        margin-left: 15px;
        color: var(--color-secondary);
      }
    }
  }

  &__chats {
    height: 100%;
    overflow-y: scroll;
    padding: 0 20px;

    &__noMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        width: 200px;
        height: 200px;
      }

      h2 {
        font-size: 20px;
        color: var(--color-grey);
      }
    }

    &__messages {
      margin-bottom: 20px;

      .user_message {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;

        img {
          width: 35px;
          height: 35px;
          border-radius: 10%;
          margin-right: 10px;
        }

        .details {
          div {
            display: flex;
            align-items: center;

            h3 {
              font-size: 15px;
              font-weight: 600;
              color: var(--color-primary);
              margin-right: 20px;
            }
          }

          p {
            font-size: 14px;
            color: #002b49;
            // width: 90%;
          }

          img {
            width: 50%;
            height: auto;
            margin-top: 10px;
          }

          small {
            font-size: 10px;
            color: var(--color-grey);
          }
        }
      }
    }
  }

  &__footer {
    // background: rgba(222, 229, 247, 0.89);
    background: rgb(230 232 237 / 89%);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 5px;
    .text_area {
      width: 90%;
      border-radius: 10px;
      border: none;
      outline: none;
      margin: 20px auto;
      height: 70px;
      padding: 10px;
    }

    input[type='file'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
      pointer-events: none;
    }

    .fileList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      width: 90%;
      border-bottom: 1px solid rgb(216, 215, 215);
      padding-bottom: 5px;

      div {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          margin-right: 10px;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          color: rgb(78, 76, 76);
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      svg {
        font-size: 15px;
        cursor: pointer;
      }
    }

    &__options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      width: 90%;

      svg {
        font-size: 17px;
        margin-right: 20px;
        color: var(--color-secondary);
      }
    }
  }
}
</style>
