import { render, staticRenderFns } from "./UsersLists.vue?vue&type=template&id=f2d30598&scoped=true"
import script from "./UsersLists.vue?vue&type=script&lang=js"
export * from "./UsersLists.vue?vue&type=script&lang=js"
import style0 from "./UsersLists.vue?vue&type=style&index=0&id=f2d30598&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d30598",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox})
