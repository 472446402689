import { gql } from '@apollo/client/core';

export const createPostMutation = gql`
  mutation createPostMutation($object: post_insert_input!) {
    insert_post_one(object: $object) {
      attachments
      companyId
      isCompanyPost
      mentions {
        companyId
        company {
          id
          name
          country
          createdAt
          createdBy
        }
        userId
        user {
          firstname
          email
          lastname
          profilePhoto
          id
          createdAt
        }
      }
      company {
        id
        name
        country
        createdAt
        createdBy
      }
      post_comments {
        postId
        content
        createdAt
        id
        userId
        isEdited
        isDeleted
        user {
          firstname
          id
          email
          profilePhoto
          lastname
        }
      }
      post_likes {
        userId
        id
        user {
          firstname
          id
          email
          lastname
          profilePhoto
        }
      }
      userId
      user {
        firstname
        email
        lastname
        profilePhoto
        id
        createdAt
      }
      content
      created_at
      id
      updated_at
      post_tags {
        id
        tag {
          name
        }
        createdAt
      }
      post_saves {
        userId
        postId
        post {
          id
        }
      }
    }
  }
`;

// export const updatePostMutation = gql`
//   mutation updatePostMutation($id: Int!, $set: post_set_input) {
//     update_post_by_pk(pk_columns: { id: $id }, _set: $set) {
//       attachments
//       companyId
//       company {
//         id
//         name
//         country
//         createdAt
//         createdBy
//         customerSegments
//       }
//       post_comments {
//         postId
//         content
//         createdAt
//         id
//         userId
//         isEdited
//         isDeleted
//         user {
//           firstname
//           id
//           email
//           profilePhoto
//           lastname
//         }
//       }
//       post_likes {
//         userId
//         id
//         user {
//           firstname
//           id
//           email
//           lastname
//           profilePhoto
//         }
//       }
//       userId
//       user {
//         firstname
//         email
//         lastname
//         profilePhoto
//         id
//         createdAt
//       }
//       content
//       created_at
//       id
//       updated_at
//       post_tags {
//         id
//         tag {
//           name
//         }
//         createdAt
//       }
//       post_saves {
//         userId
//         postId
//         post {
//           id
//         }
//       }
//     }
//   }
// `;

export const addPostLikeMutation = gql`
  mutation addPostLikeMutation($object: post_like_insert_input!) {
    insert_post_like_one(object: $object) {
      userId
      id
      user {
        email
        firstname
        id
        lastname
        profilePhoto
      }
      postId
    }
  }
`;

export const deletePostLikeMutation = gql`
  mutation deletePostLikeMutation($id: Int!) {
    delete_post_like_by_pk(id: $id) {
      id
    }
  }
`;

export const deletePostMutation = gql`
  mutation DeletePost($postId: Int) {
    update_post(where: {id: {_eq: $postId}}, _set: {isDeleted: true}) {
      affected_rows
    }
  }
`;

export const updatePostMutation = gql`
  mutation EditPostContent($postId: Int!, $updatedContent: String!) {
    update_post_by_pk(pk_columns: {id: $postId}, _set: {content: $updatedContent}) {
      content
      isEdited
    }
  }
`

export const addPostCommentMutation = gql`
  mutation addPostCommentMutation($object: post_comment_insert_input!) {
    insert_post_comment_one(object: $object) {
      content
      createdAt
      id
      replyTo
      updatedAt
      postId
      userId
      isEdited
      isDeleted
      user {
        firstname
        email
        lastname
        id
        profilePhoto
      }
    }
  }
`;

export const updatePostCommentMutation = gql`
  mutation updatePostCommentMutation($id: Int!, $set: post_comment_set_input) {
    update_post_comment_by_pk(pk_columns: { id: $id }, _set: $set) {
      content
      createdAt
      id
      replyTo
      updatedAt
      postId
      userId
      isEdited
      isDeleted
      user {
        firstname
        email
        lastname
        id
        profilePhoto
      }
    }
  }
`;

export const deletePostCommentMutation = gql`
  mutation deletePostCommentMutation($id: Int!) {
    delete_post_comment_by_pk(id: $id) {
      id
    }
  }
`;

export const addPostSaveMutation = gql`
  mutation addPostSaveMutation($object: post_save_insert_input!) {
    insert_post_save_one(object: $object) {
      id
      postId
      userId
    }
  }
`;

export const deletePostSaveMutation = gql`
  mutation deletePostSaveMutation($id: Int!) {
    delete_post_save_by_pk(id: $id) {
      postId
      userId
    }
  }
`;

export const addFollowTagMutation = gql`
  mutation MyMutation($object: follow_tag_insert_input!) {
    insert_follow_tag_one(object: $object) {
      createdAt
      id
      tagId
      userId
    }
  }
`;


export const savePostMutation = gql`
  mutation SavePostMutation($object: [post_save_insert_input!]!) {
    insert_post_save(objects: $object) {
      affected_rows
    }
  }

`

export const deleteSavedPost = gql`
  mutation DeleteSavedPost($userId: Int!, $postId: Int!) {
    delete_post_save(where: {userId: {_eq: $userId}, postId: {_eq: $postId}}) {
      affected_rows
    }
  }
`
